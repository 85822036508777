import { createInstance, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { merge } from 'lodash-es';
import { initReactI18next } from 'react-i18next';

import { resources as udacityCertificateResources } from '@udacity/udacity-certificate';
import { resources as udacityHeaderResources } from '@udacity/udacity-header';

import ar from './locales/ar-SA.json';
import de from './locales/de-DE.json';
import en from './locales/en-US.json';
import es from './locales/es-ES.json';
import frCa from './locales/fr-CA.json';
import fr from './locales/fr-FR.json';
import ko from './locales/ko-KR.json';
import ru from './locales/ru-RU.json';
import tr from './locales/tr-TR.json';
import { languages } from './languages';

const mergedResources: Resource = merge(
  {
    ar: {
      translation: ar,
    },
    de: {
      translation: de,
    },
    'en-US': {
      translation: en,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    'fr-CA': {
      translation: frCa,
    },
    ko: {
      translation: ko,
    },
    ru: {
      translation: ru,
    },
    tr: {
      translation: tr,
    },
  },
  udacityCertificateResources,
  udacityHeaderResources
);

const i18n = createInstance({
  resources: mergedResources,
  fallbackLng: languages.enUS.id,
  debug: false,
  interpolation: { escapeValue: false },
  react: { useSuspense: true },
  returnEmptyString: false,
});

i18n.use(LanguageDetector).use(initReactI18next).init();

export { i18n };
