export const JWT_COOKIE = '_jwt';
export const ANONYMOUS_COOKIE = 'ajs_anonymous_id';
export const ANONYMOUS_CREATED_AT_COOKIE = 'ajs_created_at';
export const USER_ID_COOKIE = 'ajs_user_id';
export const ACTIVE_SUBSCRIPTION_COOKIE = 'active_subscription_cookie';
export const TEAM_LEARNER_COOKIE = 'team_learner_cookie';
export const HAS_FOCUSED_EXPERIENCE_COOKIE = 'focused_experience';
export const GCLID_COOKIE = 'gclid';
export const REFERRAL_CODE_COOKIE = 'referral_code';
export const SHOULD_ASK_WELCOME_FLOW_COOKIE = 'should_ask_welcome_flow';
export const SHOULD_SHOW_WELCOME_MODAL_COOKIE = 'should_show_welcome_modal';
export const SUBSCRIPTION_REFERRER_URL_COOKIE = 'subscription_referrer_url';
export const SUBSCRIPTION_REFERRER_PROGRAM_KEY_COOKIE = 'subscription_referrer_program_key';
export const SUBSCRIPTION_REFERRER_PROGRAM_TITLE = 'subscription_referrer_program_title';
export const COUPON_COOKIE = 'coupon';
export const NEXT_LOCALE_COOKIE = 'NEXT_LOCALE';
export const CANCEL_FLOW_CLAIMED_DISCOUNT_COOKIE = 'cancel_flow_claimed_discount';
export const CANCEL_FLOW_CANCELLATION_COOKIE = 'cancel_flow_cancellation';
export const USER_TYPE_COOKIE = 'user_type';
export const RAKUTEN_SITE_ID_COOKIE = 'rakuten_site_id';
export const RAKUTEN_SESSION_START_COOKIE = 'rakuten_session_start';

// GEO Related Cookies
export const VIEWER_COUNTRY_COOKIE = 'viewer_country';
export const VIEWER_REGION_COOKIE = 'viewer_region';
export const VIEWER_CITY_COOKIE = 'viewer_city';
export const VIEWER_CURRENCY_COOKIE = 'viewer_currency';

// Promo Related Cookies
export const PROMO_COOKIE = 'promo';
export const SKIP_PROMO_COOKIE = 'skip_promo';

// Misc Cookies
export const ENABLED_FEATURE_FLAGS_COOKIE = 'enabled_feature_flags';
