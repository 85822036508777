import auth from '@udacity/ureact-hoth';

import { publicEnv } from '~/features/environment/public';
import { queryUdacityService } from '~/utils/query-udacity-service';

import { userCatalogQuery, userCatalogSchema } from '../models/user-catalog';
import {
  userHasEnterpriseEnrollmentsQuery,
  userHasEnterpriseEnrollmentsSchema,
} from '../models/user-has-enterprise-enrollments';

export async function getUserType(): Promise<'consumer' | 'enterprise' | undefined> {
  const jwt = auth.getJWT();
  const userId = auth.getCurrentUserId();

  const { hasEnterpriseEnrollments } = await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_CLASSROOM_CONTENT_URL,
    zodSchema: userHasEnterpriseEnrollmentsSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: userHasEnterpriseEnrollmentsQuery,
      variables: { uid: userId },
    }),
  });

  const { hasEmcCatalog } = await queryUdacityService({
    url: publicEnv.NEXT_PUBLIC_EXPERIENCE_URL,
    zodSchema: userCatalogSchema,
    authorization: `Bearer ${jwt}`,
    body: JSON.stringify({
      query: userCatalogQuery,
      variables: { uid: userId },
    }),
  });

  return hasEnterpriseEnrollments || hasEmcCatalog ? 'enterprise' : 'consumer';
}
