import { z } from 'zod';

import { gql } from '~/utils/gql';

export const userExperienceSchema = z
  .object({
    data: z.object({
      user: z.object({
        subscriptions: z.object({
          all_access: z.boolean(),
          teams: z.boolean().nullable(),
        }),
        classroom: z.object({
          hasFocused: z.boolean(),
          settings: z.object({
            emailEditDisabled: z.boolean().nullable(),
            nameEditDisabled: z.boolean().nullable(),
          }),
        }),
      }),
    }),
  })
  .transform(({ data }) => ({
    hasActiveSubscription: Boolean(data.user.subscriptions.all_access || data.user.subscriptions.teams),
    allAccess: data.user.subscriptions.all_access,
    teams: Boolean(data.user.subscriptions.teams),
    hasFocused: data.user.classroom.hasFocused,
    emailEditDisabled: Boolean(data.user.classroom.settings.emailEditDisabled),
    nameEditDisabled: Boolean(data.user.classroom.settings.nameEditDisabled),
  }));

export type UserExperience = z.infer<typeof userExperienceSchema>;
export type UserExperienceResponse = z.input<typeof userExperienceSchema>;

export const userExperienceQuery = gql`
  query FetchUserExperience($uid: String!) {
    user(id: $uid) {
      subscriptions {
        all_access
        teams
      }
      classroom {
        hasFocused
        settings {
          emailEditDisabled
          nameEditDisabled
        }
      }
    }
  }
`;
