import { ExperimentPage } from '~/cf-worker/types';
import * as HomepageCopyTest from '~/features/experiments/homepage-hero-copy';

export const experimentedPages: ExperimentPage[] = [
  {
    name: 'Homepage Hero Title Test',
    experimentKey: HomepageCopyTest.key,
    pathRegex: /^\/$/,
  },
  {
    name: 'Salary Section AB Test',
    experimentKey: 'AB_Salary_Test',
    pathRegex:
      // Note: Top 10 Paid PLPs
      /^\/course\/(generative-ai--nd608|product-manager-nanodegree--nd036|aws-machine-learning-engineer-nanodegree--nd189|ai-artificial-intelligence-nanodegree--nd898|c-plus-plus-nanodegree--nd213|ai-programming-python-nanodegree--nd089|ai-product-manager-nanodegree--nd088|intro-to-programming-nanodegree--nd000|intermediate-python-nanodegree--nd303|robotics-software-engineer--nd209)$/,
  },
];
