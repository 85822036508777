import { useEffect } from 'react';
import { Router, useRouter } from 'next/router';
import { experimentedPages } from 'experiment.config';

import { getViewerCurrency } from '~/features/internationalization/utils/get-viewer-currency';

/**
 * This is a hacky solution to a problem that we have with Cloudflare & Next.js.
 * The Cloudflare Worker does not run on client navigations.
 * This is a global solution to check if the user is performing a client navigation to a legal or experimented page.
 * By manually navigating the user to the same page, we force the Cloudflare Worker to run.
 * Throwing an error is apparently the only solution to stop the client navigation in Nextjs.
 */
export function useCancelClientNavigation() {
  const router = useRouter();
  const currency = getViewerCurrency();

  useEffect(() => {
    const handleRouteChange = (pathname: string) => {
      const currentPath = router.asPath;
      // Note: we want to allow client-side transitions only in checkout.
      if (pathname.startsWith('/checkout/') && !currentPath.startsWith('/checkout/')) {
        cancelClientNavigationAndManuallyRedirect(pathname);
      }

      if (pathname.startsWith('/legal/')) {
        cancelClientNavigationAndManuallyRedirect(pathname);
      }

      experimentedPages.forEach((experimentedPage) => {
        if (experimentedPage.pathRegex.test(pathname)) {
          cancelClientNavigationAndManuallyRedirect(pathname);
        }
      });
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [currency, router]);
}

function cancelClientNavigationAndManuallyRedirect(pathname: string) {
  window.location.href = pathname;
  // Ref: https://github.com/vercel/next.js/discussions/32231
  Router.events.emit('routeChangeError');
  if (process.env.NODE_ENV !== 'development') {
    throw new Error('Abort Route Change');
  }
}
